<template>
    <div class="rate-info__desc m-0">
        <h3 class="block-title rate-info__title">Детализация стоимости</h3>
        <div class="rate-info__table mt-30">
            <div class="rate-info__item">
                <div class='rate-info__label' v-if="selectedRate">Тариф - {{selectedRate.name}}</div>
                <div class="rate-info__value">
                    {{paymentDetail.price}} &#8381;/мес.
                    <button class="v-btn ml-15" @click="openRatesModal">Изменить</button>
                </div>
            </div>
            <fragment v-if="!paymentDetail.is_free_rate">
                <div class="rate-info__item" v-if="paymentDetail.user_price">
                    <div class='rate-info__label'>Дополнительные сотрудники</div>
                    <div class="rate-info__value">
                        {{paymentDetail.user_price}} &#8381;/мес за {{paymentDetail.count_users}} {{functions.num2str(paymentDetail.count_users, ['человека',
                        'человека',
                        'человек'])}}
                    </div>
                </div>
                <div class="rate-info__item" v-if="paymentDetail.add_size">
                    <div class='rate-info__label'>Дополнительное место</div>
                    <div class="rate-info__value">
                        {{paymentDetail.size_price}} &#8381;/мес за {{paymentDetail.add_size}} гб.
                    </div>
                </div>
                <div class="rate-info__item" v-if="paymentDetail.is_demo">
                    <div class='rate-info__label'>Итого</div>
                    <div class="rate-info__value">
                        <template>Бесплатно до {{ paymentDetail.date_to | moment('D MMMM YYYY') }}</template>
                    </div>
                </div>
                <div class="rate-info__item" v-if="paymentDetail.total_price">
                    <div class='rate-info__label'>Итого</div>
                    <div class="rate-info__value">
                        <template>{{paymentDetail.total_price}} &#8381;/мес ({{paymentDetail.everyday_price}} &#8381;/день)</template>
                    </div>
                </div>
            </fragment>
        </div>
    </div>

</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import functions from 'assets/js/functions';

    export default {
        name: "Detail",
        computed: {
            ...mapGetters('settings', [
                'selectedRate'
            ]),
            ...mapState('settings', [
                'rates', 'paymentDetail'
            ]),
        },
        data () {
            return {
                functions
            }
        },
        methods: {
            openRatesModal() {
                const Rates = () => import('../rates/Rates');
                this.$modal.show(Rates, {
                    rates: this.rates
                }, {
                    name: 'Rates',
                    width: '100%',
                    maxWidth: 1000,
                    height: 'auto',
                });
            },
        }
    }

</script>

<style scoped>

</style>
